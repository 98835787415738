<template>
    <div>
      <b-row>
        <b-col lg="6" md="6" sm="6" class="heading-title">
          <h4>Parent Venue</h4>
          <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
            <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
            <b-breadcrumb-item to="/manage_vendors"
              >View Vendors</b-breadcrumb-item
            >
            <b-breadcrumb-item active>Parent Venue</b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
        <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
      </b-row>
      <br />
      <b-card class="mb-4 w-100">
      <div class="venue-heading">
          <b-row>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="w-100">
               <multiselect
                  v-model="venue_selected"
                  :options="venue_option"
                  placeholder="Select Venue"
                >
                </multiselect>
              </div>
            </b-col>
            <b-col
              class="d-flex align-items-stretch "
              cols="12"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="w-100">
                <div>
                  <multiselect
                    v-model="pincode_selected"
                    :options="pincode_option"
                    placeholder="Select Pincode"
                  >
                  </multiselect>
                </div>
              </div>
            </b-col>
             <b-col
              class="d-flex align-items-stretch "
              cols="12"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="w-100">
                <div>
                  <multiselect
                    v-model="city_selected"
                    :options="city_option"
                    placeholder="Select City"
                  >
                  </multiselect>
                </div>
              </div>
            </b-col>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="12"
              sm="12"
            >
              <div class="w-100 d-flex justify-content-end button-text">
                <div class="p-1">
                  <b-button variant="primary" @click="applyFilter()">
                    Apply</b-button
                  >
                </div>
                <div class="p-1">
                  <b-button variant="primary" @click="resetFilter()">
                    Reset</b-button
                  >
                </div>
                <div class="p-1">
                  <b-button
                    id="button_width"
                    @click="export_venue()"
                    variant="primary"
                  >
                    <i class="mdi mdi-export"></i>Export</b-button
                  >
                </div>
                
              </div>
            </b-col>
          </b-row>
        </div>
  
        <div>
          <b-row> 
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="4"
              md="6"
              sm="6"
            >
              <div
                class="
                  w-100
                  justify-content-sm-center
                  justify-content-xl-start
                  justify-content-md-start
                "
              >
                <div class="mt-2">
                  <b-form-select
                  id="select_perpage"
                  v-model="pageSize"
                  :options="options"
                  @change="handlePageSizeChange($event)"
                ></b-form-select>
                </div>
              </div>
            </b-col>
            
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="8"
              md="12"
              sm="12"
            >
              <div class="w-100 p-2">
                 <b-form-input
                  v-model="search"
                  id="search"
                  type="text"
                  placeholder="Search here"
                  v-on:keyup="key_search()"
                ></b-form-input>
              </div>
            </b-col>
          </b-row>
        </div>
        <br />
        <div>
          <b-table
            :busy="load"
            :fields="fields"
            :items="child_venue"
            responsive
            hover
            bordered
            show-empty
            empty-text="No Data Available"
            class="text-center"
          >
            <template #table-busy>
              <b-skeleton-table
                v-if="load"
                :rows="10"
                :columns="6"
                :hide-header="true"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </template>
         
            <template #cell(action)="data">
              <router-link
                :to="'/Manage_vendors/parent_venue/child_venue/' +data.item.id +'/'+data.item.created_by"
              >
                <i class="fas fa-eye fa-lg text-primary"></i>
              </router-link>
             
            </template>
          </b-table>
          <b-row align-v="center">
            <b-col cols="4">
              <span
                >Showing <b>{{ totalRows }}</b> out of
                <b>{{ totalData }}</b> entries.</span
              >
            </b-col>
            <b-col cols="8">
              <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                prev-text="Prev"
                next-text="Next"
                align="right"
                @change="handlePageChange"
              ></b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
    <script>
  import axios from "axios";
  import Multiselect from "vue-multiselect";
  export default {
    name: "managevenues",
  
    data: () => ({
      title: "managevenues",
      num: 1,
      parents_id: '',
      
      fields: [
        {
          key: "parent_id",
          label: "Parent ID",
          sortable: true,
        },
        {
          key: "venue_company_name",
          label: "Venue Name",
          sortable: true,
        },
        {
          key: "parent_venue_city",
          label: "City",
          sortable: true,
        },
        {
          key: "pincode",
          label: "Pincode",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      item: [],
      value: "",
      city_selected: "",
      venue_selected: "",
      pincode_selected: "",
      child_venue: [],
      city_option: [],
      venueCounts: 0,
      options: [10, 20, 30, 50],
  
      pageSize: 10,
      show: false,
      load: true,
      filter: "",
      search: "",
      CurrentPage: 1,
      sortBy: "created_at",
  
      page: 1,
      count: 0,
      totalData: "",
      totalRows: "",
  
      PerPage: "50",
      pincode_option: [],
      venue_option: [],
    }),
    components: { Multiselect },
    methods: {
      CreateChildVanue() {
        const id = this.$route.params.id;
        this.$router.push("/venues/add-child-venue/" + id);
      },
      handlePageChange(value) {
        this.page = value;
        this.load = true;
        this.ChildVenueListing();
      },
      handlePageSizeChange(event) {
        this.pageSize = event;
        this.load = true;
        this.page = 1;
        this.ChildVenueListing();
      },
      sortChanged(e) {
        this.sortBy = e.sortBy;
        this.load = true;
        this.ChildVenueListing();
      },
      ChildVenueListing() {
        this.load = true;
        this.show = true;
        var parent_id = this.$route.params.id;
        var keyword = this.search;
        const params = this.getRequestParams(
          this.page,
          this.pageSize,
          this.sortBy
        );
        axios
          .post("/admin/parent_venue_fetch", {
            params,
            search_keyword: keyword,
            child_venue_name: this.venue_selected,
            parent_venue_id: parent_id,
            child_venue_pincode: this.pincode_selected,
            venue_city: this.city_selected,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              this.child_venue = [];
              if (resp.data.status) {
                this.count = this.totalData = resp.data.total_records;
                this.totalRows = resp.data.child_venues.length;
  
                for (var i = 0; i < resp.data.child_venues.length; i++) {
                  this.child_venue.push({
                    id: resp.data.child_venues[i].parent_venue_id,
                    created_by: resp.data.child_venues[i].created_by,
                    parent_id: resp.data.child_venues[i].parent_auto_no,
                    venue_company_name: resp.data.child_venues[i].venue_name,
                    parent_venue_city: resp.data.child_venues[i].venue_city,
                    pincode: resp.data.child_venues[i].venue_pincode,
                  });
                }
                this.show = false;
                this.load = false;
              } else {
                if (resp.data.id_not_exists == true) {
                  this.$router.push('/Manage_vendors');
                } else {
                  this.count = this.totalData = resp.data.total_records;
                  this.totalRows = resp.data.child_venues.length;
  
                  this.show = false;
                  this.load = false;
                }
              }
            }
          });
      },
      getRequestParams(page, pageSize, sortBy = "created_at") {
        let params = {};
        if (page) {
          params["page"] = page - 1;
        }
        if (pageSize) {
          params["size"] = pageSize;
        }
        params["sortBy"] = sortBy;
        return params;
      },
      venueListing() {
        axios
          .post("/admin/child_venue_pincode_city_listing", {
            parent_venue_id: this.$route.params.id,
            vendor_id: this.$route.params.vendor_id,
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              if (resp.data.status) {
                this.venue_option = resp.data.child_venue_names;
                this.pincode_option = resp.data.child_venue_pincodes;
                this.city_option = resp.data.child_venue_cities;
              }
            }
          });
      },
      applyFilter() {
        const value = "1";
        this.handlePageChange(value);
      },
      resetFilter() {
        this.pincode_selected = "";
        this.venue_selected = "";
        this.city_selected = "";
        this.search = "";
        this.ChildVenueListing();
      },
      key_search() {
        const value = "1";
        this.handlePageChange(value);
      },
  
      export_venue() {
        var parent_id = this.$route.params.id;
        var keyword = this.search;
        const params = this.getRequestParams(
          this.page,
          this.pageSize,
          this.sortBy
        );
        axios
          .post(
            "/api/export_child_pdf",
            {
              params,
              search_keyword: keyword,
              child_venue_name: this.venue_selected,
              parent_venue_id: parent_id,
              child_venue_pincode: this.pincode_selected,
              venue_city: this.city_selected,
              vendor_id: this.$route.params.vendor_id,
            },
            {
              responseType: "blob",
            }
          )
          .then((resp) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "child_venue_list.pdf");
            document.body.appendChild(link);
            link.click();
          });
      },
    },
  
    mounted() {
      this.parents_id =this.$route.params.id;
      this.ChildVenueListing();
     // this.venueListing();
    },
  };
  </script>
    <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
    <style scoped>
  
  </style>